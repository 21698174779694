import {cva} from 'class-variance-authority';

import {twMerge} from '@/stylesheets/twMerge';
import Link from '@/components/base/elements/Link/Link';
import {useSiteData} from '@/hooks/useSiteData';

import type {TestimonialTextProps} from '../types';

/**
 * Locales that require a `&nbsp;` prefix and suffix in `quoteHtml`
 */
const localesWithNbsp: {[x: string]: boolean} = {
  es: true,
  'es-CO': true,
  'es-ES': true,
  'es-MX': true,
  fr: true,
  'fr-BE': true,
  'fr-CA': true,
  it: true,
};

const wrapper = cva('flex flex-col h-full top-0 z-0', {
  /**
   * @todo These styles ideally should not be in this component but should be
   * passed down from `<Testimonials>` because they are only relevant when this
   * component is rendered as part of multiple `<Testimonial>` components within
   * `<Testimonials>`
   */
  variants: {
    isActive: {
      true: 'opacity-1 delay-[350ms]',
      false: 'opacity-0 pointer-events-none',
    },
    isInCarousel: {
      true: [
        /**
         * This pseudo element is vital to ensure the height of the testimonial
         * is correctly set. It serves as a placeholder/spacer for the absolutely
         * positioned `<DirectionalControls />` component
         */
        'after:relative after:w-full after:h-11 after:mt-md after:pointer-events-none',
        'transition-opacity duration-[350ms] ease-linear',
      ],
    },
  },
});

const quote = cva(
  'grow before:content-[open-quote] before:absolute before:-translate-x-full after:content-[close-quote]',
  {
    variants: {
      fontSize: {
        large:
          'text-[28px] leading-[36px] md:text-[36px] md:leading-[44px] lg:text-[44px] lg:leading-[52px]',
        small:
          'text-[24px] leading-[32px] md:text-[28px] md:leading-[36px] lg:text-[32px] lg:leading-[40px]',
      },
    },
    defaultVariants: {
      fontSize: 'large',
    },
  },
);

const byline = cva('flex flex-col gap-y-xs mt-lg text-body-base', {
  /**
   * @todo These styles ideally should not be in this component but should be
   * passed down from `<Testimonials>` because they are only relevant when this
   * component is rendered as part of multiple `<Testimonial>` components within
   * `<Testimonials>`
   */
  variants: {
    isActive: {
      true: 'delay-500 opacity-100',
      false: 'delay-[250ms] opacity-0',
    },
    isInCarousel: {
      true: 'transition-opacity duration-500 ease-linear',
    },
  },
});

function getIsActiveTabIndex(
  ariaHidden: boolean | undefined,
  isInCarousel: boolean,
  isActive: boolean | undefined,
) {
  /**
   * When the parent Testimonial is set to `aria-hidden="true"`, we shouldn't
   * set the `tabIndex` to `0`
   */
  if (ariaHidden) return false;
  if (!isInCarousel) return true;
  return isActive;
}

/**
 * The text of a testimonial, includes the quote and byline with optional link
 */
export default function TestimonialText({
  className,
  author,
  authorTitle,
  brand,
  link,
  quoteHtml,
  isActive,
  ariaHidden,
  mode,
  size,
}: TestimonialTextProps) {
  const QUOTE_LENGTH_THRESHOLD = 90;
  const fontSize =
    size ??
    (quoteHtml && quoteHtml.length > QUOTE_LENGTH_THRESHOLD
      ? 'small'
      : 'large');
  const {site} = useSiteData();
  const isInCarousel = isActive !== undefined;
  const isActiveTabIndex = getIsActiveTabIndex(
    ariaHidden,
    isInCarousel,
    isActive,
  );

  return (
    <blockquote
      tabIndex={isActiveTabIndex ? 0 : -1}
      className={twMerge(wrapper({isActive, isInCarousel}), className)}
    >
      <p
        className={quote({fontSize})}
        dangerouslySetInnerHTML={{
          __html: localesWithNbsp[site.locale]
            ? `&nbsp;${quoteHtml}&nbsp;`
            : quoteHtml,
        }}
      ></p>

      {(brand || author || link) && (
        <footer className={byline({isActive, isInCarousel})}>
          {brand && <p className="text-t6">{brand}</p>}
          {author && (
            <cite className="text-body-sm not-italic">
              <strong>{author}</strong>
              {author && authorTitle && ` — ${authorTitle}`}
            </cite>
          )}
          {link && (
            <Link
              arrow={true}
              size="large"
              mode={mode}
              tabIndex={isActiveTabIndex ? 0 : -1}
              {...link}
            >
              {link.text}
            </Link>
          )}
        </footer>
      )}
    </blockquote>
  );
}
